<template>
  <div class="quota">
    <div class="quota-content">
      <div :class="['quota__main', { improved: isCompletelyImproved }]">
        <div>
          <span class="title__exporter">
            {{ `Экспортёр: ${getQuotaExporter}  /` }}
          </span>
          <span class="title__description">{{ getTitle }}</span>
          <Icon name="IconTruckIconQuota" width="15px" height="11px" />).
        </div>

        <div
          v-if="!isMobile"
          :class="[
            'quota-content__icon-wrap',
            { exporter: isExporter },
            { support: isSupport || isViewer },
          ]"
        >
          <Icon
            v-if="!isSupport && !isViewer"
            name="IconPen"
            width="20px"
            height="18px"
            @onClick="handleEditCulture"
          />
          <Icon
            v-if="!isExporter && !isSupport && !isViewer"
            name="IconTrash"
            width="15px"
            height="18px"
            @onClick="handleDeleteCulture(quota)"
          />
          <Icon
            name="ArrowDownIcon"
            width="22px"
            height="22px"
            :class="['arrow', { rotated: isOpen }]"
            @onClick="isOpen = !isOpen"
          />
        </div>
      </div>

      <div class="quota__progressbar">
        <div class="quota__progressbar-info">
          <div :class="{ overflow: quota.count > quota.trucks_allowed_count }">
            {{ getProgressBarParams(quota).title }}
          </div>
          <div class="info__value">
            {{ quota.count }}/{{ quota.trucks_allowed_count }}
          </div>
        </div>

        <el-progress
          :text-inside="true"
          :stroke-width="progressWidth"
          :percentage="getProgressBarParams(quota).percentage"
          :color="getProgressBarParams(quota).color"
        />
      </div>
    </div>
    <el-collapse-transition>
      <QuotaShared v-show="isOpen && !isMobile" :quota="quota" />
    </el-collapse-transition>
    <AlarmDeleteModal />
  </div>
</template>

<script>
import {
  DIALOG_ADD_QUOTA,
  DIALOG_IMPROVE_QUOTA,
  HOME_DELETE_QUOTA,
} from '@/constants/dialogs'
import {
  GET_EXPORTERS_ALL,
  GET_PREPARE_CULTURE_FROM_STATE,
} from '@/views/control/store/actions'
import { GET_IS_MOBILE } from '@/store/actions'
import { GET_USER_EXPORTERS } from '@/views/exporter/store/actions'
import { autoTypeSupervisor } from '@/constants/auto-type'
import { getProgressBarParams } from '@/core'
import { mapGetters } from 'vuex'
import AlarmDeleteModal from './AlarmDeleteModal'
import Icon from '@/UI/icon/Icon'
import QuotaShared from './QuotaShared.vue'

export default {
  name: 'QuotaContent',
  components: {
    QuotaShared,
    AlarmDeleteModal,
    Icon,
  },
  props: {
    quota: {
      type: Object,
      required: true,
    },
    del: {
      type: Boolean,
      required: false,
    },
    edit: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      visibleDeleteModal: false,
      visibleDeleteNonSlotModal: false,
      getProgressBarParams,
    }
  },
  computed: {
    ...mapGetters({
      culturesList: GET_PREPARE_CULTURE_FROM_STATE,
      allExportersList: GET_EXPORTERS_ALL,
      userExportersList: GET_USER_EXPORTERS,
      isMobile: GET_IS_MOBILE,
    }),
    exportersList() {
      return this.isExporter ? this.userExportersList : this.allExportersList
    },
    getTitle() {
      return `${this.getQuotaCultures} по ${this.quota.trucks_per_hour}
              авто в час${this.getQuotaTypeAuto}`
    },
    getQuotaCultures() {
      return (
        this.quota.cultures_ids.reduce((accum, item) => {
          if (this.culturesList[item]?.name) {
            accum += `${this.culturesList[item]?.name.trim()}${
              this.culturesList[item]?.harvest_year
                ? ' / год урожая: ' + this.culturesList[item]?.harvest_year
                : ''
            },`
          }

          return accum
        }, '') || 'Неизвестная культура,'
      )
    },
    getQuotaExporter() {
      let exporter = this.exportersList.find(
        item => item.id === this.quota.exporters_ids[0],
      )

      return exporter ? exporter.name : 'не указан'
    },
    getQuotaTypeAuto() {
      if (
        this.quota.truck_type_codes.length === autoTypeSupervisor.length ||
        !this.quota.truck_type_codes.length
      ) {
        return ', (любые).'
      }
      let typeNames = []

      this.quota.truck_type_codes.forEach(item => {
        typeNames.push(autoTypeSupervisor.find(name => name.id === item).name)
      })

      return `, ${typeNames.join(', ')}`
    },
    progressWidth() {
      return this.isMobile ? 8 : 16
    },
    isCompletelyImproved() {
      if (!this.quota.child_quotas || !this.quota.child_quotas.length) {
        return false
      }

      let improved = this.quota.child_quotas.reduce(
        (sum, item) => sum + item.trucks_allowed_count,
        0,
      )

      return improved === this.quota.trucks_allowed_count
    },
  },
  watch: {
    del: {
      handler() {
        this.handleDeleteCulture()
      },
    },
    edit: {
      handler() {
        this.handleEditCulture()
      },
    },
  },
  methods: {
    handleDelete(data) {
      this.setDialog({ name: HOME_DELETE_QUOTA, visible: true, data })
    },

    handleEditCulture() {
      this.setDialog({
        name: this.isExporter ? DIALOG_IMPROVE_QUOTA : DIALOG_ADD_QUOTA,
        data: { ...this.quota, isEdit: true },
        visible: true,
      })
      this.$emit('emitClose')
    },
    handleDeleteCulture() {
      this.handleDelete({
        id: this.quota.id,
        count: this.quota.count,
      })
      this.$emit('updateBeforeDelete')
      this.$emit('emitClose')
    },
  },
}
</script>

<style lang="sass">
.quota
  flex: 1 0 71%

.quota-content
  display: flex
  justify-content: space-between
  align-items: center

  &__icon-wrap
    flex: 0 0 87px
    display: flex
    align-items: center
    margin-right: 15px
    &.exporter
      flex: 0 0 58px
      margin-right: 0
    &.support
      flex: 0 0 29px
      margin-right: 0
    .arrow
      transition: transform 0.3s ease
      &.rotated
        transform: rotate(180deg)
      &.iq-icon:hover *
        fill: none
        stroke: $color-blue-primary

  &__icon
    font-size: 20px
    cursor: pointer
    color: $color-black
    transition: color .3s ease
    & + &
      margin-left: 10px
    &:hover
      color: $color-blue

  .iq-icon
    cursor: pointer
    *
      transition: fill .3s ease, stroke .3s ease
    &:hover *
      fill: $color-blue-primary

.quota__main
  flex: 2 0 250px
  display: flex
  justify-content: space-between
  align-items: center
  margin-right: 14px
  padding: 9px 13px
  background: #F0F1F7
  border: 1px solid #DCDCDC
  border-radius: 8px
  font-weight: 500
  font-size: 14px
  line-height: 16px
  letter-spacing: 0.5px
  &.improved
    background: #F0F9EB
  &.mod-child
    flex: 2 0 223px
  .title__exporter
    color: $grey-font
  .title__description
    color: $main-font
  .title__unimproved
    font-weight: 900
    color: $color-orange-primary

.quota__progressbar
  flex: 1 0 150px
  .el-progress-bar__outer
    border: 1px solid #EBEEF5
  .el-progress-bar__innerText
    font-size: 0
  &-info
    display: flex
    justify-content: flex-end
    align-items: center
    font-size: 16px
    line-height: 19px
    letter-spacing: 0.5px
    color: $main-font
    margin-bottom: 2px
    .overflow
      color: $color-orange-primary
    .info__value
      width: 70px
      font-weight: 900
      font-size: 14px
      line-height: 16px
      letter-spacing: 0.5px
      text-align: right
      color: $color-orange-primary

@media (max-width: 1200px)
  .quota-content
    flex-direction: column
    flex: auto
    align-items: flex-start
    width: 100%
    .quota__main
      flex: auto
      margin-right: 0
      border: none
      background: #fff
      padding: 0
      margin-bottom: 16px
      &.improved
        background: #F0F9EB
    .quota__progressbar
      flex: auto
      width: 100%
    &__main-ts
      justify-content: space-between
      font-size: 12px
      margin-bottom: 0
      &-count
        font-size: 12px
</style>
